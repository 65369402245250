<script>
import _ from "lodash";
import draggable from 'vuedraggable';
import NextArrowIcon from "@/assets/img/next-arrow.svg";
import DragIndicatorIcon from "@/assets/img/drag-indicator.svg";
import DragIndicatorCircleUp from "@/assets/img/drag-indicator-circle-up.svg";
import DragIndicatorCircleDown from "@/assets/img/drag-indicator-circle-down.svg";

export default {
  name: "DragRank",
  props: {
    title: {
      type: String,
      required: false
    },
    options: {
      type: Array,
      required: true
    },
    handleChange: {
      type: Function,
      required: true
    },
    optionSelected: {
      type: String,
      required: false,
      default: []
    },
    disabled: {
      type: Boolean,
      required: false
    },
    isMainQuestion: {
      type: Boolean,
      required: false,
      default: false
    },
    isAiGenerated: {
      type: Boolean,
      required: false,
      default: false
    },
    previousAnswer: {
      type: Object,
      required: false
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },
    revisitingSegment: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      list: this.previousAnswer ? _.map(_.get(this.previousAnswer, "selection"), (name, i) => {
        return { name, order: i + 1 }
      }) : _.map(this.options, (name, i) => {
        return { name, order: i + 1 }
      }),
      NextArrowIcon,
      DragIndicatorIcon,
      DragIndicatorCircleUp,
      DragIndicatorCircleDown,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 150,
        group: "people",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },
  methods: {
    handleAnswer(){
      const indexOfList = _.map(this.list, v => {
        return _.indexOf(this.options, v.name)
      })
      this.handleChange(_.map(this.list, v => v.name), indexOfList)
    },
    handleManual(elementName, up){
      const indexOfClicked = _.findIndex(this.list, v => v.name === elementName);
      if (up){
        const prevElement = _.get(this.list, `[${indexOfClicked - 1}]`, null);
        if (prevElement !== null){
          this.list[indexOfClicked - 1] = this.list[indexOfClicked];
          this.list[indexOfClicked] = prevElement;
        }
      } else {
        const nextElement = _.get(this.list, `[${indexOfClicked + 1}]`, null);
        if (nextElement !== null){
          this.list[indexOfClicked + 1] = this.list[indexOfClicked];
          this.list[indexOfClicked] = nextElement;
        }
      }
    }
  },
};
</script>

<template>
  <div class="ddr-container">
    <div class="drr-question-title">
      Order by Dragging:
    </div>
    <div>
      <draggable
          class="list-group"
          item-key="order"
          :disabled="readOnly"
          :component-data="{ tag: 'ul', name: 'flip-list', type: 'transition' }"
          v-model="list"
          v-bind="dragOptions"
          :move="checkMove"
      >
        <template #item="{ element }">
          <li :class="`list-group-item ${readOnly ? 'list-group-item-disabled' : 'list-group-item-enabled'}`">
            <div class="flex">
              <img :src="DragIndicatorIcon" alt="" />
              <div class="list-item-label">
                {{ element.name }}
              </div>
            </div>
            <div class="list-group-item-help-icon-container">
              <img style="height: 24px; width: 24px; z-index: 9; cursor: pointer" :src="DragIndicatorCircleDown" alt="" @click="()=>handleManual(element.name, false)" />
              <img style="height: 24px; width: 24px; z-index: 9; cursor: pointer" :src="DragIndicatorCircleUp" alt="" @click="()=>handleManual(element.name, true)" />
            </div>
          </li>
        </template>
      </draggable>
    </div>
    <div class="ddr-submit-button" v-if="!readOnly">
      <button class="app-submit-button" @click="handleAnswer"><img style="margin: 0 auto" :src="NextArrowIcon" alt=""></button>
    </div>
  </div>
</template>

<style scoped>
li {
  list-style-type: none;
}
.ddr-container {
  width: 600px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.ddr-submit-button{
  align-self: flex-start;
  margin-top: 10px;
}

.ddr-submit-button-control{
  border: 1px solid #D7D7D7;
  border-radius: 3px;
  padding-left: 10px;
  padding-right: 10px;
  background: #8737E11A;
  height: 40px;
  color: #333333;
}

.button {
  margin-top: 35px;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 1;
  background: #8737E11A;
  color: #8737E11A;
  box-shadow: 0 0 8px 2px #00000066;
}

.drr-question-title{
  display: flex;
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 16px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #000000;
  justify-content: space-between;
  align-items: center;
}
.list-group {
  min-height: 40px;
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.list-group-item {
  display: flex;
  justify-content: space-between;
}
.list-group-item-help-icon-container {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-right: 10px;
}
.list-group-item-enabled {
  cursor: move;
  min-height: 40px;
  box-shadow: 0 0 4px 0 #00000080;
  border: 1px solid #8737E1;
  line-height: 40px;
  padding-left: 10px;
  border-radius: 10px;
}

.list-group-item-disabled {
  cursor: move;
  min-height: 40px;
  box-shadow: 0 0 8px 2px #00000066;
  border: 1px solid rgb(215 215 215 / 30%);
  line-height: 40px;
  padding-left: 10px;
  border-radius: 10px;
}
.list-item-label {
  padding-left: 15px;
}
.sortable-chosen {
  background: #FFFFFF;
  color: #000000;
  box-shadow: 0 0 8px 2px #00000066;
}
.drr-free-text-input-submit-button {
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  background: #9B9B9B;
  margin-top: 10px;
  height: 40px;
  line-height: 40px;
  width: 90px;
}
</style>