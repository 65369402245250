<script>
import _ from "lodash";
import "vue3-circle-progress/dist/circle-progress.css";
import CircleProgress from "vue3-circle-progress";
export default {
  name: "CircularProgress",
  components: {CircleProgress},
  props: {
    value: {
      type: Number,
      required: true
    }
  },
  computed: {
    strokeColor(){
      return _.toNumber(this.value) > 75 ? "#AEDA50" : "#FD922D"

    }
  }
}
</script>

<template>
<div>
  <circle-progress :style="`color: ${strokeColor}`" :size="30" :border-width="3" :border-bg-width="3" :fill-color="strokeColor"	:percent="value" :show-percent="true" empty-color="#FFFFFF"/>
</div>
</template>

<style>
.vue3-circular-progressbar .current-counter {
  font-size: 12px;
  font-weight: 500;
}
</style>