<script>
import _ from "lodash";
import AnyUserIcon from "../assets/img/Group.svg";
import DownArrowIcon from "../assets/img/down-arrow.svg";
import UpArrowIcon from "../assets/img/up-arrow.svg";
import {jwtDecode} from "jwt-decode";

export const TOP_STRIP_MODES = {
  PAGE: "PAGE",
  SURVEY: "SURVEY"
};

export default {
  name: "TopStrip",
  props: {
    progressPercent: {
      type: Number,
      required: false,
      default: 0
    },
    mode: {
      type: String,
      required: false,
      default: TOP_STRIP_MODES.SURVEY
    }
  },
  data(){
    return {
      AnyUserIcon,
      DownArrowIcon,
      UpArrowIcon,
      showingMenu: false,
      TOP_STRIP_MODES
    }
  },
  methods: {
    handleUserMenuButtonClick(){
      this.showingMenu = !this.showingMenu
    },
    logout() {
      localStorage.clear();
      this.$router.push({ name: "login" });
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.showingMenu = false
      }
    }
  },
  computed: {
    userName(){
      const token = localStorage.getItem('accessToken')
      if (!token){
        localStorage.clear(); // Clear local storage
        this.$router.push({ name: "login" }); // Redirect to home page
      }
      const decoded = jwtDecode(token);
      return decoded.name;
    }
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside);
  },
}
</script>

<template>
  <div class="ts-container">
    <div class="ts-progress-bar-outer" v-if="mode === TOP_STRIP_MODES.SURVEY">
      <div class="ts-progress-bar-inner"
           :style="`width: ${progressPercent}%; background: linear-gradient(90deg, #AEDA50 ${progressPercent}%, #F6F6F6 100%);`">
      </div>
      <div class="ts-progress-bar-text">{{progressPercent}}% completed</div>
    </div>
    <div v-if="mode === TOP_STRIP_MODES.PAGE">
      <div class="ts-brand-logo">
        KEARNEY
      </div>
    </div>
    <div class="ts-user-menu-container">
      <div class="ts-user-menu" @click="handleUserMenuButtonClick">
        <div class="ts-user-menu-user-image" :style="`background-image: url(${AnyUserIcon});`"></div>
        <div class="flex-grow flex-1">{{userName}}</div>
        <button>
          <img :src="showingMenu ? UpArrowIcon : DownArrowIcon" alt="" />
        </button>
      </div>
      <div v-if="showingMenu" class="ts-user-menu-option">
        <ol class="ts-user-menu-option-list">
          <li class="ts-user-menu-option-listitem" @click="logout">Logout</li>
        </ol>
      </div>
    </div>
  </div>
</template>

<style scoped>
.ts-brand-logo {
  font-size: 32px;
  font-weight: 400;
  padding-left: 20px;
}
.ts-container{
  width: 100%;
  height: 98px;
  display: flex;
  box-shadow: 0 4px 43px 0 #63068A1F;
  align-items: center;
}

.ts-progress-bar-outer {
  width: 80%;
  height: 28px;
  background: #F6F6F6;
  overflow: hidden;
  border-radius: 23px;
  margin: 20px;
  display: flex;
}


.ts-progress-bar-inner {
  height: 28px;

}
.ts-progress-bar-text{
  padding-left: 10px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
}
.ts-user-menu-container {
  width: 20%;
  height: 50px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
  margin: 20px 20px 20px auto;
}

.ts-user-menu {
  width: 100%;
  height: 100%;
  border-radius: 35px;
  display: flex;
  box-shadow: 0 0 7px 0 #00000030;
  align-items: center;
  padding: 10px;
}

.ts-user-menu-user-image {
  height: 35px;
  width: 35px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.ts-user-menu-option {
  width: 80%;
  align-self: center;
  margin-top: 3px;
}

.ts-user-menu-option-list {
  background-color: #FFFFFF;
}

.ts-user-menu-option-listitem {
  height: 100%;
  box-shadow: 0 0 7px 0 #00000030;
  align-items: center;
  border-radius: 10px;
  text-align: center;
  padding: 10px
}
</style>