import _ from 'lodash';
import pillarApi from '@/services/survey/pillarApi';

const aa = [
    {
        "id": "0",
        "type": "SS",
        "options": [
            "North America",
            "South America",
            "Eastern Europe",
            "Western Europe",
            "Middle East",
            "Africa",
            "Eurasia (CIS / Russia)",
            "East Asia (China, Japan, Taiwan, South Korea)",
            "Southeast Asia",
            "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
            "Other (please specify)"
        ],
        "parent": [],
        "parentOptions": {},
        "title": "1.1 In which geographies is your company primarily operating?"
    },
    {
        "id": "1",
        "type": "RR",
        "options": [
            "Minimize COGS/sales ratio.",
            "Maximize outsourcing degree.",
            "Optimize time to market.",
            "Ensure product supply service level.",
            "Develope strategic sites.",
            "Strengthen technology leadership.",
            "Others (please specify)"
        ],
        "parent": [
            "0"
        ],
        "parentOptions": {
            "0": [
                0,
                1,
                2,
                3,
                4,
                5,
                6,
                7,
                8,
                9,
                10
            ]
        },
        "title": "6.1 Please indicate the importance of the following criteria in reference to your capital investments:"
    },
    {
        "id": "2",
        "type": "UD Agree, Neutral, Disagree",
        "options": [
            "For new construction or opening",
            "For refurbishing",
            "For facility maintenance, repair, or minor upgrade"
        ],
        "parent": [
            "1"
        ],
        "parentOptions": {
            "1": [
                0,
                1,
                2
            ]
        },
        "title": "2.1.1.1 Are you planning an increase or decrease of capex in the next few years?"
    },
    {
        "id": "3",
        "type": "FTPO",
        "options": [
            "New construction or opening",
            "Refurbishing",
            "Facility maintenance, repair, or minor upgrade"
        ],
        "parent": [
            "2"
        ],
        "parentOptions": {
            "2": [
                0
            ]
        },
        "title": "2.1.1 What is the average amount of capex spent last year per category (m$)? (Free quantitative answers for each answer choice)"
    },
]
const devResponse = {
    data: {
        Result:  {
            "pillar": "Design & Engineering",
            "pillar_id": 3,
            "segments": [{
                "id": 2,
                "questions": [
                    {
                        "id": "0",
                        "type": "MS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia)",
                            "Other (please specify)"
                        ],
                        "parent": [],
                        "parentOptions": {},
                        "title": "1.1 In which geographies is your company primarily operating?",
                    },
                    {
                        "id": "1",
                        "type": "SS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                            "Other (please specify)"
                        ],
                        "parent": ["0"],
                        "parentOptions": {
                            "0": [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        "title": "1.1 In which geographies is your company primarily operating?",
                        "answer": {
                            "selection": [
                                "South America",
                                "Western Europe"
                            ],
                            "optionNumber": 5
                        },
                    },
                    {
                        "id": "2",
                        "type": "SS",
                        "options": [
                            "North America",
                            "South America",
                            "Eastern Europe",
                            "Western Europe",
                            "Middle East",
                            "Africa",
                            "Eurasia (CIS / Russia)",
                            "East Asia (China, Japan, Taiwan, South Korea)",
                            "Southeast Asia",
                            "Oceania (Australia, New Zealand, Papua New Guinea, Pacific Islands)",
                            "Other (please specify)"
                        ],
                        "parent": ["1"],
                        "parentOptions": {
                            "1": [0,1,2,3,4,5,6,7,8,9,10]
                        },
                        "title": "1.1 In which geographies is your company primarily operating?",
                        "answer": {
                            "selection": [
                                "South America",
                                "Western Europe"
                            ],
                            "optionNumber": 5
                        },
                    }
                ],
                "status": 0,
                "is_skipped": 0
            }]
        }
    }
}

const formatResponseForUI = (resp) => {
    const respResult = resp?.data?.Result || {};
    return {
        pillarName: respResult.pillar,
        pillarId: respResult.pillar_id,
        segmentId: _.get(respResult.segments, "[0].id"),
        questions: _.get(respResult.segments, "[0].questions"),
        isSkipped: respResult.is_skipped,
        status: respResult.status

    }
}

export default async (pillarId, segmentId)=>{
    let url = "pillar_segment";
    if (pillarId && segmentId){
        url = `${url}?pillar_id=${pillarId}&segment=${segmentId}`
    }
    const response = await pillarApi.getData(url).catch(e => {
        return {
            error: true,
            errorMessage: e
        }
    });
    // const response = await new Promise((resolve)=>{
    //     setTimeout(()=>{
    //         resolve(devResponse)
    //     }, 300)
    // })
    return formatResponseForUI(response);
}