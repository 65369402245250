<script>
import Logo from "@/assets/img/kearney-logo-black.svg";
import CiruclarProgress from "@/components/CiruclarProgress.vue";

export default {
  name: "NavBar",
  components: {
    CiruclarProgress
  },
  props: {
    menuOptions: {
      type: Object,
      required: true
    },
    onNavItemSelection: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      Logo,
    }
  },
  methods: {
    onMenuItemClick(newIndex){
      this.onNavItemSelection(newIndex)
    }
  },

  computed: {
    navItemSelectedIndex() {
      return this.menuOptions?.selected;
    }
  },

  watch: {
    navItemSelectedIndex(newIndex){
      this.onNavItemSelection(newIndex)
    }
  }
}
</script>

<template>
  <div class="sidebar">
    <div class="flex flex-col h-full justify-between">
      <div class="flex flex-col">
        <div class="logo-image">
          <img :src="Logo" alt="logo">
        </div>
        <div class="navigation-items">
          <div v-for="(item, index) in menuOptions?.items"
               :class="`navigation-item-primary ${navItemSelectedIndex === index ? 'navigation-item-primary-selected' : ''}`"
               :style="`${item.completion > 0 && navItemSelectedIndex !== index ? 'color: #333333' : ''}`"
               @click="()=>onMenuItemClick(index)"
               :key="item.title">
            {{item.title}}
            <div class="navigation-item-progress">
              <CiruclarProgress :value="item.completion" />
            </div>
          </div>
        </div>
      </div>
      <div class="navigation-bottom-section">
        <div class="navigation-b-sub">Powered By</div>
        <div class="navigation-b-main">KEARNEY</div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.navigation-item-progress {
}
.sidebar {
  position: fixed;
  left: 0;
  height: 100vh;
  width: 289px;
  background: #ffffff;
  overflow-y: auto;
  box-shadow: 9px 4px 24px 0 #8737E11A;
}

.logo-image {
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  height: 68px;
}

.logo-image > img {
  margin: 20px auto;
}

.navigation-items {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-top: 46px;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.navigation-item-primary {
  text-align: left;
  font-size: 14px;
  padding: 10px 10px 10px 20px;
  background-color: #F5F6F8;
  color: #AAB5B7;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigation-item-primary-selected {
  background-color: #8737E1;
  color: #FFFFFF;
}

.navigation-bottom-section {
  margin-bottom: 20px;
  text-align: center;
}

.navigation-b-sub {
  font-size: 10px;
  font-weight: 400;
  color: #8737E1;
}

.navigation-b-main {
  font-size: 16px;
  font-weight: 500;
  color: #8737E1;
  letter-spacing: 2px;
}

.navigation-logout-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  cursor: pointer;
}

.navigation-logout-button {
  display: flex;
  justify-content: flex-start;
  color: #f4f4f6;
  gap: 10px;
  align-items: center;
  padding-left: 20px;
}

.navigation-logout-arrow {
  margin-right: 10px;
  display: flex;
}

.navigation-logout-user-icon {
  width: 20px;
  height: 20px;
}

.navigation-research-titles {
  overflow-y: auto;
  height: 60vh;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.navigation-research-titles::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
</style>